.page-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0;
  pointer-events: none;
  z-index: 9999;
  transition: opacity 0.5s ease;
  will-change: opacity;

  &.active {
    opacity: 1;
    pointer-events: all;
  }
} 