@import "../../styles/variables.scss";
@import '../../styles/colors.scss';

.camera-screen-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $background-color;


}

.camera-section {
  // border-radius: 60px;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1);
  padding: 0;
}

.parent-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  box-sizing: border-box;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--bg-image);
    background-size: cover;
    background-position: center;
    filter: blur(80px);
    opacity: 0.5;
    z-index: 0;
  }

  .captured-image, .captured-video {
    height: 100%;
    width: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1;
  }
}

.progress-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 20px;

  .layout-button {
    svg {
      width: 32px;
      height: 32px;
    }
  }
  .qr-button {
    svg {
      width: 32px;
      height: 32px;
    }
  }
}

.control-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: none;
  background: rgba(26, 26, 26, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  svg {
    path, rect {
      stroke: #666666;
      transition: stroke 0.3s ease;
    }
    rect[fill="white"] {
      fill: #666666;
    }
  }

  &:disabled {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    cursor: not-allowed;
    
    svg {
      path, rect {
        stroke: #666666;
      }
      rect[fill="white"] {
        fill: #666666;
      }
    }
  }

  &.qr-button:not(:disabled) {
    background: rgba(255, 152, 0, 0.5);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    svg {
      path, rect {
        stroke: white;
      }
      rect[fill="white"] {
        fill: white;
      }
    }
  }
}

.face-overlay {
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    z-index: 1000;
    /* Ensure it's on top of other elements */
}

.loading-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 24px;
  
  .background-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: rgba(26, 26, 26, 0.5);
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);
    // border-radius: 24px;
    z-index: 1;
  }
  
  .progress-layer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(var(--progress, 0%));
    background: $primary-color;
    transition: width 0.3s ease;
    transform-origin: left;
  }
  
  .progress-text {
    color: white;
    font-size: 24px;
    font-weight: bold;
    position: relative;
    z-index: 3;
  }
}

.qr-modal {
  padding: 24px;
  width: 260px;
  height: 310px;
  border-radius: 24px;
  // max-height: 1px;
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  // padding-bottom: 400px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
  z-index: 1000;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(255, 255, 255, 0.95);
  }

  .qr-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    p {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 14px;
      text-align: center;
    }
  }
}

.content-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  svg {
    margin-bottom: 20px;
    height: auto;
    width: 224px;
    max-height: 40%;
    min-height: 100px;
  }
}

.content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  
  .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 60px;
    height: 60px;
    background: transparent;
    border: none;
    padding: 0;
    z-index: 10;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.1);

    .close-icon {
      position: relative;
      width: 100%;
      height: 100%;
      
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 2px;
        background-color: white;
        transform-origin: center;
      }
      
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.generation-message {
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  
  &.fade-out {
    opacity: 0;
  }
}

.wand-animation {
  pointer-events: none;
  will-change: transform;
  width: 200px;
  height: auto;
  max-width: 100%;
  mix-blend-mode: screen;
  
  &[src$=".mp4"] {
    object-fit: contain;
    max-height: 40%;
  }
  
  &[src*="done"] {
    width: 150px;
    height: 150px;
  }
}

