@import "../../styles/colors";

// Базовые стили для popover
.popover {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  
  
//   margin-top: 8px;
}

.popover-content {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
//   bottom: 100px;
  background-color: $panel-background;
  
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  backdrop-filter: blur(80px);
  min-width: 300px;
  box-shadow: 0 4px 80px rgba(0, 0, 0, 0.8);
}

.popover-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.popover-menu {
//   color: #666;
  cursor: default;
//   background: none;
  padding-left: 16px;
  padding-right: 16px;
  
  li {
    padding: 14px 14px;
    cursor: pointer;
    font-size: 18px;
    color: rgba(255, 255, 255, 0.8);
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    .material-symbols-outlined {
      font-size: 20px;
    }
  }

  .balance-info {
    display: flex;
    align-items: center;
    background: none;
    
    .balance-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      
      gap: 12px;

      .generation-label {
        display: flex;
        align-items: center;
        gap: 2px;
        opacity: 0.8;
      }

      .balance-count {
        font-size: 28px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
      }
    }

    .image-icon {
      width: 18px;
      height: 18px;
      fill: currentColor;
    }
  }

  .fullscreen-icon {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 12px;
    gap: 8px;
    
    width: calc(100% - 24px);
    height: 80px;
    margin: 16px auto;
    margin-bottom: 28px;
    position: relative;
    left: auto;
    top: auto;
    
    border: 1px solid rgba(235, 200, 200, 0.1);
    border-radius: 16px;
    color: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    
    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
    
    &::after {
      content: "Полный экран";
      font-size: 14px;
    }
    
    .icon-circle {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 0.3s ease;
    }
    
    svg {
      color: white;
    }
  }

  .username {
    font-size: 20px;
    font-weight: 500;
    color: #6f6f6f;
    // margin-left: 4px;
  }
}

.image-icon {
  width: 18px;
  height: 18px;
  margin-right: 4px;
  fill: currentColor;
}

.leading-zeros {
  color: #353535;
  opacity: 0.5;
  font-size: 28px;
} 