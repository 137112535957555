@import "../../styles/colors";



.top-menu-wrapper {
  background: $panel-background;
  backdrop-filter: blur(80px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: calc(100% - 40px);
  height: 100px;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 40px;
  display: flex;
  min-width: 320px;
  align-items: center;
  border: 1px solid $border-color;

  @media (max-width: 1280px) {
    height: 90px;
    margin-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
    width: calc(100% - 30px);
    border-radius: 30px;
  }

  @media (max-width: 480px) {
    height: 80px;
    margin-top: 10px;
    margin-left: 10px; 
    margin-right: 10px;
    width: calc(100% - 20px);
    border-radius: 25px;
  }
}

.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.left-section {
  display: flex;
  align-items: center;
}

.middle-section {
  flex-grow: 1;
}

.right-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.settings-button {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  transition: background-color 0.2s ease;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.2);
    // Добавим явный стиль для отладки
    outline: 2px solid rgba(255, 255, 255, 0.3);
  }

  svg {
    width: 30px;
    height: 30px;
  }
}

.filter-controls {
  display: flex;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 10px;
  }

  .filter-button {
    padding: 10px 20px;
    border: 1px solid;
    border-radius: 50px;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    background-color: transparent;
    color: white;
    border-color: #ffffff20;

  

    &.male {
      // border-color: #ffffff20;
      // color: #66cc33;
    }

    &.female {
      // border-color: #f3e4ff;
      // color: #9966cc;
    }

    &.kids {
      // border-color: #ffe9dd;
      // color: #ff9900;
    }

    &:hover {
      opacity: 0.9;
    }

    &.active {
     
        background-color: $primary-color;
        // color: #66cc33;
      
    }
  }
}

.selected-style {
  text-align: left;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  // background-color: rgba(255, 255, 255, 0.1);         
  // border: 1px solid rgba(255, 255, 255, 0.1);
  // border-radius: 12px;
  transition: background-color 0.3s ease;

  

  .selected-style-image {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    margin-right: 20px;
    object-fit: cover;

    
  }

  .selected-style-info {
    display: flex;
    width: auto;
    flex-direction: column;
  }

  .selected-style-prefix {
    color: $font-color;
    opacity: 0.5;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .selected-style-group {
    color: $font-color;
    text-align: left;
    font-size: 20px;
    font-weight: bold;

   
  }
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  outline: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.fullscreen-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    opacity: 0.8;
  }

  .material-symbols-outlined {
    font-size: 24px;
  }
}
