@import "../../styles/colors";

.style-grid {
  position: fixed;
  top: 150px;
  bottom: 250px;
  left: 0;
  right: 0;
  width: 100%;

  @media (max-width: 1280px) {
    top: 130px;
    bottom: 195px;
  }

  @media (max-width: 480px) {
    top: 180px; 
    bottom: 190px;
  }
}

.image-container {
  // scroll-snap-type: x mandatory;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  touch-action: pan-x;
  -webkit-touch-action: pan-x;
  display: flex;
  gap: 1rem;
  padding: 4px calc(50% - ((100vh - 300px) * 1 / 3));
  height: calc(100% - 8px);
  scroll-padding: 0 calc(50% - ((100vh - 300px) * 1 / 3));
  overscroll-behavior-x: contain;
}

.image-card {
  scroll-snap-align: center;
  flex: 0 0 auto;
  width: calc((100vh - 300px) * 2 / 3);
  height: calc(100% - 8px);
  
  position: relative;
  cursor: pointer;
  border-radius: 60px;
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.3s ease;

  &.selected {
    box-shadow: 0 0 0 4px $primary-color;
    position: relative;
    overflow: hidden;
    
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: radial-gradient(50% 50% at 50% 50%, $primary-color, transparent 70%);
      background-position: 50% -50%;
      background-size: 200% 200%;
      background-repeat: no-repeat;
      opacity: 0.7;
      animation: 4s linear radial-glow infinite;
      z-index: 1;
      border-radius: 60px;
    }
  }

  .circle-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
  }
}

.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  
  .turbo-icon {
    position: absolute;
    top: 40px;
    left: 40px;
    font-size: 24px;
    z-index: 2;
    width: 44px;
    height: 44px;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .glow-effect {
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-size: cover;
    background-position: center;
    filter: blur(10px) brightness(1.5);
    opacity: 0.7;
    z-index: -1;
    transition: all 0.3s ease;
  }
}

.style-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
}

.style-name {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.group-name {
  display: block;
  font-size: 0.8em;
  opacity: 0.8;
}

.camera-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: $primary-color;
  border: none;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .material-symbols-outlined {
    font-size: 35px;
    color: white;
  }
}

@media (max-width: 768px) {
  .style-grid {
    top: 80px;
    bottom: calc(160px + 30px);
  }

  .image-card {
    width: calc((100vh - 290px) * 2 / 3);
  }

  .image-container {
    padding: 4px calc(50% - ((100vh - 290px) * 1 / 3));
    scroll-padding: 0 calc(50% - ((100vh - 290px) * 1 / 3));
  }
}

@keyframes radial-glow {
  0%, 100% {
    background-position: 50% -50%;
  }
  25% {
    background-position: 150% 50%;
  }
  50% {
    background-position: 50% 150%;
  }
  75% {
    background-position: -50% 50%;
  }
}
