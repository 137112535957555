// Colors
$primary-color: #000000;
$background-color: #000000;
$text-color: #000000;
$error-color: #FF0000;

// Spacing
$spacing-small: 8px;
$spacing-medium: 16px;
$spacing-large: 24px;

// Border radius
$border-radius: 8px;
$border-radius-large: 16px;

// Font sizes
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-large: 20px;
$font-size-xlarge: 24px;

// Breakpoints
$mobile: 480px;
$tablet: 768px;
$desktop: 1024px;

// Z-index levels
$z-index-base: 1;
$z-index-overlay: 100;
$z-index-modal: 1000; 