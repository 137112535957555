.style-group-toggle {
  background: rgba(32, 33, 35, 0.9);
  border-radius: 12px;
  padding: 12px;
  margin: 8px 0;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.style-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 12px;
}

.style-name {
  color: #ffffff;
  flex-grow: 1;
  font-size: 16px;
}

/* Стили для переключателя */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #4a4a4a;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(26px);
} 