.loading-progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.1);
  z-index: 1000;

  &__bar {
    height: 100%;
    background: #fff;
    transition: width 0.3s ease-out;
  }
} 