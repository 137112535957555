//html, body {
//  margin: 0;
//  padding: 0;
//  width: 100%;
//  height: 100%;
//  overflow: auto; // Предотвращаем прокрутку страницы
//}

.frameOverlay {
  pointer-events: none; // Отключаем взаимодействие указателя с этим элементом
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;

  svg {
    width: 100%;
    height: 100%;

  }


}
