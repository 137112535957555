.packages-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.package-card {
  background: #303030;
  border-radius: 8px;
  padding: 7px;
  padding-top: 17px;
  text-align: center;
  position: relative;
  
  .package-amount {
    padding-top: 10px;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .package-price-per-unit {
    font-size: 34px;
    font-weight: bold;
    padding-top: 10px;
    margin: 0;
    line-height: 32px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .package-subtitle {
    color: #888;
    margin-bottom: 15px;
  }
  
  .package-button {
    width: 100%;
    padding: 12px;
    background: #0066FF;
    border: none;
    border-radius: 6px;
    color: white;
    font-weight: 600;
    font-size: 1.1em;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    
    &:hover {
    //   background: linear-gradient(45deg, #1976D2, #1565C0);
      transform: translateY(-2px);
      //box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }
    
    &:active {
      transform: translateY(0);
      //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
    
    &:disabled {
      background: linear-gradient(45deg, #9E9E9E, #757575);
      cursor: not-allowed;
      transform: none;
      box-shadow: none;
      opacity: 0.7;
    }
  }
}

.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .payment-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    position: relative;
    overflow: auto;
    
    iframe {
      width: 100%;
      min-height: 500px;
      border: none;
    }

    .close-button {
      position: absolute;
      right: 10px;
      top: 10px;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      padding: 0;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      
      &:hover {
        color: #666;
      }
    }

    h3 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 15px;
    }
  }
}

.payment-qr-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  .qr-modal-content {
    // transform: translateX(20%);
    background: white;
    padding: 20px;
    border-radius: 30px;
    text-align: center;
    position: relative;
    max-width: 90%;
    width: 350px;
    height: auto;

    .close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      color: #666;
      
      &:hover {
        color: #333;
      }
    }


    .qr-code-container {
      margin: 1.5rem 0;
      display: flex;
      justify-content: center;
      
      canvas {
        border: 1px solid #eee;
        padding: 10px;
        border-radius: 8px;
      }
    }

    p {
      // margin: 0.5rem 0;
      text-align: center;
      color: #666;
    }

    .payment-link {
      display: inline-block;
      margin-top: 1rem;
      padding: 0.8rem 1.5rem;
      background-color: #007bff;
      color: white;
      text-decoration: none;
      border-radius: 6px;
      transition: background-color 0.2s;

      &:hover {
        background-color: #0056b3;
      }
    }
  }
}

.amount-badge {
  background-color: #242424;
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.best-deal-badge {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: #ff4444;
  color: white;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  z-index: 1;
}

.available-generations-label {
  font-size: 16px;
  font-weight: 800;
  margin-right: 8px;
  color: #ffffff;
}

.image-icon {
  transform: translateY(4px);
  margin-right: 8px;
}

.packages-section {
  .packages-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h4 {
      margin: 0;
    }

    .accordion-arrow {
      transition: transform 0.3s ease;
    }

    &.expanded {
      .accordion-arrow {
        transform: rotate(180deg);
      }
    }
  }

  .packages-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    
    &.expanded {
      max-height: 2000px; // Достаточно большое значение для отображения всего контента
    }
  }
}

.balance-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  padding: 0;
  margin: 0;

  .add-balance-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #0056b3;
    }
  }
}

.packages-content {
  display: none;
  
  &.expanded {
    display: block;
  }
}

.screen-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;

  .back-button {
    position: absolute;
    width: 100px;
    height: 50px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 8px 12px;
    border: none;
    background: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    background-color: #0056b3;
    
    &:hover {
      opacity: 0.8;
    }
  }

  h4 {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  margin-bottom: 18px;
  // margin-bottom: 1rem;

  h4 {
    margin: 0;
    padding: 0;
    padding-bottom: 4px;
  }

  .balance-info {
    height: 40px;
    background: none;
    box-shadow: 0 0 0 1px #393939;
    border-radius: 8px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    
    .image-icon {
      transform: translateY(-1px);
      width: 20px;
      height: 20px;
    }
  }
}

