body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: none;
  -ms-touch-action: none;
  user-zoom: none;
  -webkit-user-zoom: fixed;
}

