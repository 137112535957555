@import "../../styles/colors";

:root {
  --secondary-color: rgba(255, 255, 255, 0.1);
  --background-gradient: linear-gradient(135deg, #1e1e1e 0%, #2d2d2d 100%);
  --container-background: rgba(255, 255, 255, 0.1);
  --form-border-color: rgba(255, 255, 255, 0.2);
  --input-bg-color: rgba(255, 255, 255, 0);
  --error-bg-color: rgba(220, 53, 69, 0.2);
  --success-bg-color: rgba(40, 167, 69, 0.2);
  --info-bg-color: rgba(0, 123, 255, 0.2);
  --warning-bg-color: rgba(255, 193, 7, 0.2);
  --error-color: #ff6b6b;
  --error-border-color: #ff4757;
}

.register-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--background-gradient);

  .register-container {
    display: flex;
    background: var(--container-background);
    backdrop-filter: blur(10px);
    border-radius: 60px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--form-border-color);
    width: 100%;
    max-width: 400px;

    .form-side {
      flex: 1;
      padding: 2rem;
      width: 100%;

      .system-message {
        position: relative;
        padding: 1rem;
        border-radius: 12px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-weight: 500;
        animation: fadeIn 0.3s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;

        .message-content {
          flex: 1;
        }

        .close-button {
          background: none;
          border: none;
          color: inherit;
          font-size: 1.5rem;
          cursor: pointer;
          padding: 0 0.5rem;
          opacity: 0.7;
          transition: opacity 0.2s;

          &:hover {
            opacity: 1;
          }
        }
      }

      .error-message {
        background: var(--error-bg-color);
        color: #ff6b6b;
        padding: 1rem;
        border-radius: 12px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-weight: 500;
        border-left: 4px solid #ff6b6b;
        animation: fadeIn 0.3s ease-in-out;
      }

      .success-message {
        background: var(--success-bg-color);
        color: #2ecc71;
        padding: 1rem;
        border-radius: 12px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-weight: 500;
        border-left: 4px solid #2ecc71;
        animation: fadeIn 0.3s ease-in-out;
      }

      .info-message {
        background: var(--info-bg-color);
        color: #3498db;
        padding: 1rem;
        border-radius: 12px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-weight: 500;
        border-left: 4px solid #3498db;
        animation: fadeIn 0.3s ease-in-out;
      }

      .warning-message {
        background: var(--warning-bg-color);
        color: #f39c12;
        padding: 1rem;
        border-radius: 12px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-weight: 500;
        border-left: 4px solid #f39c12;
        animation: fadeIn 0.3s ease-in-out;
      }

      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(-10px); }
        to { opacity: 1; transform: translateY(0); }
      }

      form {
        text-align: left;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        
        .form-group {
          margin-bottom: 2.5rem;
          position: relative;

          input {
            width: 100%;
            height: 64px;
            padding: 0 24px;
            box-sizing: border-box;
            background-color: var(--input-bg-color);
            border: 2px solid var(--form-border-color);
            border-radius: 50px;
            color: #ffffff;
            font-size: 18px;
            font-weight: bold;
            transition: all 0.3s ease;
            -webkit-appearance: none;
            appearance: none;

            &:focus {
              outline: none;
              background-color: rgba(255, 255, 255, 0.15);
              border-color: rgba(255, 255, 255, 0.3);
            }

            &::placeholder {
              color: rgba(255, 255, 255, 0.5);
            }
            
            &.error {
              border-color: var(--error-border-color);
              background-color: rgba(255, 0, 0, 0.05);
            }
          }
          
          .error-message {
            color: var(--error-color);
            font-size: 14px;
            margin-top: 8px;
            padding: 8px 16px;
            background-color: rgba(255, 0, 0, 0.1);
            border-radius: 20px;
            text-align: left;
            animation: fadeIn 0.3s ease-in-out;
            border-left: none;
            margin-bottom: 0;
          }
          
          .input-error-message {
            color: var(--error-color);
            font-size: 14px;
            margin-top: 8px;
            padding: 8px 16px;
            background-color: rgba(255, 0, 0, 0.1);
            border-radius: 20px;
            text-align: left;
            animation: fadeIn 0.3s ease-in-out;
            border-left: none;
            margin-bottom: 0;
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            z-index: 1;
            top: 100%;
            left: 0;
          }
        }

        button {
          margin-top: 32px;
          width: 100%;
          height: 80px;
          padding: 1rem;
          background: $primary-color;
          border: none;
          border-radius: 50px;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            transform: translateY(-2px);
          }

          &:active {
            transform: translateY(0);
          }
          
          &:disabled {
            background: rgba(128, 128, 128, 0.5);
            cursor: not-allowed;
            transform: none;
            opacity: 0.7;
            
            &:hover {
              transform: none;
            }
          }
        }
      }
    }
  }

  .forgot-password-link {
    position: absolute;
    padding-top: 48px;
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    
    a {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.5);
      text-decoration: none;
      white-space: nowrap;
      margin-left: 5px;
      padding: 5px 15px;
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      transition: all 0.3s ease;
      
      &:hover {
        text-decoration: none;
        color: $primary-color;
        border-color: $primary-color;
      }
    }
  }
}

@media (max-width: 480px) {
  .register-container {
    width: 100%;
    padding: 1rem;
  }

  .form-group {
    margin-bottom: 1rem;
  }
}