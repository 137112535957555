@import "../../styles/colors";

.mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1e1e1e 0%, #2d2d2d 100%);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__content {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 24px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 2rem;
    text-align: center;
    width: calc(100% - 32px);
    max-width: 400px;
    margin: 16px;
    animation: fadeIn 0.3s ease-in-out;
    
    h2 {
      color: #ffffff;
      margin-bottom: 16px;
      font-size: 24px;
      width: 100%;
      text-align: center;
    }
    
    p {
      margin: 0;
      color: rgba(255, 255, 255, 0.8);
      font-size: 16px;
      line-height: 1.5;
      width: 100%;
      text-align: center;
    }
  }
}

@keyframes fadeIn {
  from { 
    opacity: 0; 
    transform: translateY(-20px);
  }
  to { 
    opacity: 1; 
    transform: translateY(0);
  }
}

/* Tablets and larger devices */
@media (min-width: 769px) {
  .mobile-overlay {
    display: none;
  }
} 