@import "./colors";


.App {
  text-align: center;
  min-width: 320px;  // Соответствует минимальной ширине body
  min-height: 100vh;

  font-family:
    Srbija Sans,
    sans-serif;
  overflow-y: hidden;
  touch-action: pan-x pan-y;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overscroll-behavior: none;
  touch-action: pan-y pinch-zoom;
}

/* styles.css */
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* Скрыть скроллбары для всех элементов */
* {
  -ms-overflow-style: none;
  /* IE и Edge */
  scrollbar-width: none;
  /* Firefox */
  touch-action: manipulation;
}

*::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari и Opera */
}

html, body {
  margin: 0;
  padding: 0;
  min-width: 320px;  // Минимальная ширина для мобильных устройств
  min-height: 100vh;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  background: $background;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
}

body {
  background: black;
  //padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  touch-action: pan-x pan-y;
  //touch-action: pan-x pan-y;
  overscroll-behavior: none;
  /* Prevent overscroll behavior */
}

@font-face {
  font-family: 'Inter', sans-serif;
  /* Имя, которое вы хотите использовать для шрифта */
  // src: url("../assets/fonts/srbija_sans.woff") format("woff");
  /* Путь к файлу шрифта */
  font-weight: normal;
  /* Укажите вес шрифта, если необходимо */
  font-style: normal;
  /* Укажите стиль шрифта, если необходимо */
}

@font-face {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-style: normal;
}

/*button {*/
/*  margin: 10px;*/
/*  padding: 10px 20px;*/
/*  font-size: 16px;*/
/*  cursor: pointer;*/
/*}*/



h2 {
  font-size: 32px;
  margin-left: 5vh;
  //margin-bottom: 0vh;
  text-align: left;
  color: $h2;
  font-family: 'Inter', sans-serif;
  // -webkit-text-fill-color: transparent;
}

h5 {
  color: black;
  // text-align: left;
  //margin-bottom: 0vh;
  //font-family: Montserrat;
  //margin-left: 5vh;
  font-size: 18px;
  font-style: normal;
  // font-weight: 700;
}

p {
  margin-left: 16px;
  color: #7d7d7d;
  text-align: left;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // padding-bottom: 5vw;
}

// Заголовки используют Srbija Sans
h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  
}

// Весь остальной текст использует Golos Sans
body, p, span, div, button, input, textarea {
  font-family: 'Inter', sans-serif;
}
