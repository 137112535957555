.notification {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 24px 48px;
  border-radius: 20px;
  z-index: 1000;
  white-space: nowrap;
  pointer-events: none;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.visible {
    opacity: 1;
  }

  &.error {
    background-color: rgba(255, 0, 0, 0.8);
  }
  
  &.success {
    background-color: rgba(0, 128, 0, 0.8);
  }
}