@import "../../styles/colors";

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin circle-button($size: 100px) {
  width: $size;
  height: $size;
  border-radius: 50%;
  border: none;
  @include flex-center;
  cursor: pointer;
  transition: transform 0.2s ease;
  -webkit-tap-highlight-color: transparent;
  transform-origin: center center;

  &:active {
    transform: scale(0.9);
  }
}

.camera-screen-container {
  display: flex;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  gap: 20px;
  box-sizing: border-box;

  @media all and (orientation: landscape) {
    flex-direction: row !important;
  }

  @media all and (orientation: portrait) {
    flex-direction: column !important;
    height: auto;
    min-height: 100vh;
  }
}

.camera-section {
  flex: 1;
  position: relative;
  border-radius: 60px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;

  @media all and (orientation: portrait) {
    flex: none;
    width: 100%;
    height: 0;
    padding-bottom: 50%;
    
    .parent-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.second-section {
  flex: 1;
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;

  @media all and (orientation: landscape) {
    height: 100%;
  }

  .content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 20px;
    box-sizing: border-box;
    background: $panel-background;
    backdrop-filter: blur(10px);
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 60px;

    .content-blocks {
      width: 100%;
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;
    }

    .content-block {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      pointer-events: none;
      gap: 20px;

      &.active {
        opacity: 1;
        pointer-events: auto;
      }

      .cam-info-image {
        max-width: 20%;
        height: auto;
      }
      
      h1 {
        color: $primary-color;
        font-size: 32px;
        margin: 0;
      }

      p {
        color: #fff;
        margin: 0;
        padding: 0;
        font-size: 18px;
        line-height: 1.5;
        max-width: 70%;
        width: 100%;
        text-align: center;
      }
    }

    .indicator-dots {
      padding-top: 20px;
      position: relative;
      display: flex;
      justify-content: center;
      gap: 8px;

      .dot {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.3);
        transition: background-color 0.3s ease;

        &.active {
          background-color: white;
        }
      }
    }

    .close-button {
      position: absolute;
      top: 30px;
      right: 30px;
      width: 48px;
      height: 48px;
      padding: 0;
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 50%;
      @include flex-center;
      cursor: pointer;
      
      .close-icon {
        position: relative;
        width: 20px;
        height: 20px;
        
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 2px;
          background-color: white;
        }
        
        &::before {
          transform: translate(-50%, -50%) rotate(45deg);
        }
        
        &::after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &:hover {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
  gap: 20px;
}

.info-block {
  text-align: left;
  display: flex;
  align-items: center;
  bottom: 0;
  z-index: 3;
  color: #ffffff;
  // padding-bottom: 50px;
  position: fixed;
}

.cam-guide {
  order: -1;
  margin-right: 20px;
  width: 53px;
  height: 53px;
  background-image: url("../../assets/cam_guide.png");
}

.face-guide {
  pointer-events: none;
  z-index: 5;
  position: absolute;
  transform: scale(0.8);
  background-image: url("../../assets/oval.png");
}

.back-button {
  z-index: 1000;
  margin: 0;
  height: 100px;
  border: none;
  cursor: not-allowed;
  position: fixed;
  border-radius: 35px;
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  background: rgba(74, 74, 74, 0.16);
  border: 1px solid #333333;
  backdrop-filter: blur(27.95px);
  color: white;
  width: 400px;
  left: 25px;
  bottom: 30px;
}

.parent-container {
  position: relative;
  width: 100%;
  height: 100%;
  @include flex-center;
  border-radius: 60px;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--bg-image);
    background-size: cover;
    background-position: center;
    filter: blur(80px);
    opacity: 0.5;
    z-index: 0;
  }

  .videoInsert {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    border-radius: 60px;
  }

  .captured-image {
    position: absolute !important;
    inset: 0 !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    z-index: 1;
    transform: scaleX(-1);
    border-radius: 60px;
    margin: 0 !important;
    padding: 0 !important;
  }

  .canvas-hidden {
    display: none;
  }

  .overlay-svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 70%;
    width: auto;
    pointer-events: none;
    z-index: 5;
    
    * {
      stroke: white;
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
      height: 60%;
      transform: translate(-50%, -70%);
    }

    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      height: 75%;
      transform: translate(-50%, -65%);
    }

    @media all and (orientation: landscape) {
      transform: translate(-50%, -60%);
    }
  }
}

.white-screen {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d1bc9d;
  z-index: 1001;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  pointer-events: none;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
  }
}

.blurred-background {
  transform: scaleX(-1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;

  video {
    opacity: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(60px);
    transform: translateZ(0);
  }
}

.content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.delete-image-button {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 10px;
  right: 20px;
  border: none;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-size: 40px;
}

.video-style {
  position: relative;
  overflow: hidden;
}

.svg-top-margin {
  margin-top: 5px;
}

.countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  font-size: 100px;
  color: white;
}

.overlay-frame {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  // backdrop-filter: blur(80px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.hole {
  width: 700px;
  height: 700px;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -55%);
  overflow: hidden;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7);
  backdrop-filter: none;
}

.hole::before {
  content: "";
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  // background: radial-gradient(
  //   circle,
  //   rgba(255, 255, 255, 0.2) 0%,
  //   rgba(0, 0, 0, 0) 70%
  // );
  border-radius: 50%;
}

.button-controls {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  width: 100%;

  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

.control-button {
  @include circle-button(64px);
  svg {
    stroke: none;
    
    path {
      stroke: none;
      filter: none;
      box-shadow: none;
    }
  }

  &.refresh-button {
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    stroke: #fff;
  }

  &.send-button {
    background-color: #007AFF;
    stroke: none;
    
    svg path {
      stroke: none;
      filter: none;
      box-shadow: none;
    }
  }
}

.capture-button {
  @include circle-button(100px);
  background-color: rgba($primary-color, 0.8);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  &:active {
    transform: translateX(-50%) scale(0.9);
    background-color: #f0f0f0;
  }
}

.countdown-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .countdown-text {
    position: absolute;
    color: white;
    font-size: 32px;
  }
}

.error-message {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #ff4444;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 14px;
}

@keyframes blinkFade {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.parent-container {
  position: relative;
}

.overlay-svg {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70%;
  width: auto;
  pointer-events: none;
  z-index: 5;
}

.content-wrapper {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .cam-info-image {
    // margin-bottom: 20px;
    max-width: 100%;
    height: auto;
  }
  
  h1 {
    margin-bottom: 12px;
  }
  
  p {
    margin-bottom: 20px;
  }
}

