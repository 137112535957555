@import "../../styles/colors";

:root {
  --secondary-color: rgba(255, 255, 255, 0.1);
  --background-gradient: linear-gradient(135deg, #1e1e1e 0%, #2d2d2d 100%);
  --container-background: rgba(255, 255, 255, 0.1);
  --form-border-color: rgba(255, 255, 255, 0.2);
  --input-bg-color: rgba(255, 255, 255, 0);
}

.email-confirmation-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--background-gradient);

  .email-confirmation-container {
    display: flex;
    background: var(--container-background);
    backdrop-filter: blur(10px);
    border-radius: 60px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--form-border-color);
    width: 100%;
    max-width: 400px;

    .form-side {
      flex: 1;
      padding: 2rem;
      width: 100%;
      text-align: center;

      .loading-message {
        background: var(--secondary-color);
        color: #ffffff;
        padding: 1.5rem;
        border-radius: 8px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 18px;
      }

      .error-message {
        background: var(--secondary-color);
        color: $primary-color;
        padding: 1.5rem;
        border-radius: 8px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 18px;
      }

      .success-message {
        background: var(--secondary-color);
        color: #2e7d32;
        padding: 1.5rem;
        border-radius: 8px;
        margin-bottom: 1.5rem;
        text-align: center;
        font-size: 18px;
        line-height: 1.5;
      }

      .action-buttons {
        margin-top: 2rem;
        
        button {
          width: 100%;
          height: 80px;
          padding: 1rem;
          background: $primary-color;
          border: none;
          border-radius: 50px;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            transform: translateY(-2px);
          }

          &:active {
            transform: translateY(0);
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .email-confirmation-container {
    width: 100%;
    padding: 1rem;
  }
} 