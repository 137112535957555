@import "../../styles/colors";

.style-group-panel {
  background: $panel-background;
  position: fixed;
  // left: 20;
  // right: 20;
  bottom: 20px;
  width: calc(100% - 40px);
  border-radius: 30px;
  margin-left: 20px;
  margin-right: 20px;
  // padding: 20px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // min-width: 320px;
  max-height: calc(100vh - 100px);
  border: 1px solid $border-color; // Добавляем обводку

  @media (max-width: 1280px) {
    max-height: calc(100vh - 200px);

    .category-image {
      width: 90px;
      height: 90px;
      border-radius: 20px;
    }
    
    .group-item {
      padding-top: 15px;
      padding-bottom: 15px;
      
      h5 {
        font-size: 16px;
        margin: 12px 0 0;
      }
    }
    
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 140px);
    bottom: 10px;
    
    .category-image {
      width: 90px;
      height: 90px;
      border-radius: 20px;
    }
    
    .group-item {
      padding-top: 15px;
      padding-bottom: 15px;
      
      h5 {
        font-size: 16px;
        margin: 12px 0 0;
      }
    }
  }
}

.group-items {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  // padding: 20px ;
  // padding-left: 15px;
  // margin: 10px;
  max-width: 100%;
  
  // scroll-snap-type: x proximity;
  mask-image: linear-gradient(
    to right,
    black calc(100% - 100px),
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    to right,
    black calc(100% - 100px),
    transparent 100%
  );


}

.group-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  transition: transform 0.2s ease;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  cursor: pointer;
  flex: 0 0 auto;
  
  h5 {
    font-size: 18px;
    font-weight: 500;
    margin: 16px 0 0;
    text-align: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.5); // Делаем текст серым по умолчанию
    transition: color 0.3s ease; // Добавляем плавный переход
  }
  
  &.active {
    .category-image {
      border: 2px solid #fff;
      transform: scale(1.05);
    }
    
    h5 {
      color: #fff; // Белый цвет для активного элемента
      font-weight: bold;
    }
  }
}

.category-image {
  width: 120px;
  height: 120px;
  border-radius: 25px;
  object-fit: cover;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

span {
  font-size: 12px;
  opacity: 0.7;
}

.time-and-frame-count {
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-top: 10px;

  @media (max-width: 360px) {
    font-size: 12px;
    margin-top: 6px;
  }
}
