.styles-settings {
  // padding: 20px;

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  h3 {
    margin-bottom: 0;
    font-size: 1.2rem;
    color: #333;
  }

  .settings-content-container {
    background: #2A2A2A;
    border: 1px solid #343434;
    border-radius: 8px;
    // padding: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .style-groups {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    padding: 10px;
  }

  p {
    color: #6b6b6b;
    text-align: center;
    width: 100%;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
  }

  .toggle-all-buttons {
    display: flex;
    gap: 10px;
    
    button {
      padding: 6px 12px;
      border: none;
      border-radius: 4px;
      background-color: transparent;
      color: #666;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 0.9rem;
      display: flex;
      align-items: center;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
        color: #333;
      }

      &:active {
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  }
} 