

.settings-content-container {
    background: #2A2A2A;
    border: 1px solid #343434;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    
    &:last-child {
      margin-bottom: 0;
    }

  p {

    margin: 0;


    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    /* or 144% */
    color: #6b6b6b;

  }

  a {
    color: #6b6b6b;
    text-decoration: underline;
    transition: color 0.2s;

    &:hover {
      color: #7AB8FF;
    }
  }

  h4 {
    /* Пополнить баланс генераций */
  margin: 0;
  padding-bottom: 12px;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;

  color: #ffffff;


  }
  }
  
  