@import "../../styles/colors";


.app {
  display: flex;
  background: $background;
  flex-direction: column;
  position: relative;

}

.cards {
  position: relative;
  z-index: 1;
}

.gradient {
  z-index: 0;
}
