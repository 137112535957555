.circle-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;

  
  &:disabled {
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    pointer-events: none;
    opacity: 0.7;
    
    .loading-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      
      .progress-layer {
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
      }
    }
  }

  &.refresh-button {
    // background: rgba(0, 0, 0, 0.5);

  }

  &.send-button {
    // background: rgba(0, 122, 255, 0.5);

  }

  &.qr-button {
    // background: rgba(255, 152, 0, 0.5);

  }

  &.capture-button {
    width: 100px;
    height: 100px;
    // background: rgba(255, 255, 255, 0.5);

  }

  &__icon {
    &--done {
      width: 32px;
      height: 32px;
      margin-right: 4px;
    }
    
    &--image {
      width: 28px;
      height: 28px;
      margin-right: 8px;
    }
    
    &--print {
      width: 34px;
      height: 34px;
      margin-right: 0px;
    }
    
    &--qr {
      width: 42px;
      height: 42px;
      margin-right: 0px;
    }
    
    &--refresh {
      width: 32px;
      height: 32px;
      margin-right: 2px;
    }
  }

  &--default {
    // background-color: #ffffff;
  }

  &--primary {
    // background-color: #007bff;
    color: white;
  }

  &--secondary {
    // background-color: #6c757d;
    // color: white;
  }

  &:active {
    transform: scale(0.95);
  }
} 

