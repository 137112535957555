.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
}

.modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  height: 600px;
  max-width: 90vw;
  max-height: 85vh;
  background: #262626;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  // padding: 30px;
  color: white;
  backdrop-filter: blur(80px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 15px;

  h2 {
    margin: 0;
    color: white;
    font-size: 24px;
    font-weight: 500;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 28px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    transition: color 0.3s ease;
    
    &:hover {
      color: white;
    }
  }
}

.modal-body {
  font-size: 16px;
  line-height: 1.5;
  height: calc(100% - 60px);
  overflow: hidden;
  flex: 1;
}

.settings-container {
  display: flex;
  width: 100%;
  height: 100%;
  
  .settings-menu {
    flex-shrink: 0;
    width: 150px;
    padding: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #1E1E1E;

    .menu-item {
      padding: 12px 20px;
      cursor: pointer;
      margin-bottom: 10px;
      border-radius: 10px;
      // transition: background-color 0.2s;
      color: rgba(255, 255, 255, 0.8);
      

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
        border-radius: 10px;
      }

      &.active {
        background-color: #0066FF;
        color: white;
        border-radius: 10px;
        
      }
    }
  }

  .settings-content {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    color: white;
    
    h3 {
      color: white;
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
}

.user-info {
  margin-top: 20px;
  padding: 15px;
  // background-color: #f5f5f5;
  border-radius: 8px;

  p {
    margin: 8px 0;
    font-size: 16px;
    
    strong {
      color: #007AFF;
    }
  }
}

.balance-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: rgba(32, 33, 35, 0.5);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  
  p {
    margin: 0;
  }
}

.add-balance-button {
  padding: 0.5rem 1rem;
  background-color: #0066FF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0052cc;
  }

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
}

.styles-settings {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  h3 {
    margin-bottom: 16px;
  }

  .style-groups {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    padding-right: 10px;
  }

  .style-toggle-container {
    .style-preview {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 0.5rem;

      img.category-image {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 8px;
      }

      span {
        font-weight: 500;
      }
    }
  }
}

.balance-info {
  margin: 20px 0;
  padding: 15px 20px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  
  p {
    margin: 0;
    font-size: 18px;
    
    strong {
      color: #007AFF;
      font-size: 20px;
    }
  }
}