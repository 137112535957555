// _colors.scss
$h2: white;

$primary-color: #007aff; 
$border-color: #ffffff10; 
$background: #000000;
$panel-background: #141414;
$font-color: white;

$card-background: rgba(255, 255, 255, 0.04);
$card-background-selected: white;
$card-border-selected: #103b44;
